import React from 'react';
import { motion } from 'framer-motion';
import { Code, Lightbulb, Rocket, ChevronUp } from 'lucide-react';
import RossPFP from './Images/Ross.jpg';
import JustinPFP from './Images/Justin.jpg';

const About = () => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const teamMembers = [
    { 
      name: "Justin Nel", 
      role: "Co-Founder", 
      bio: "Leader with a focus on product and growth.",
      image: JustinPFP
    },
    { 
      name: "Ross Nelson", 
      role: "Co-Founder", 
      bio: "Expert in application and platform development.",
      image: RossPFP
    },
  ];

  return (
    <div className="bg-[#082340] text-white">
      <div className="container mx-auto py-20 px-4">
        <motion.h1 
          className="text-6xl font-bold mb-8 text-center"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          About DRFT Studios
        </motion.h1>
        <div className="max-w-3xl mx-auto">
          <motion.p 
            className="text-xl mb-6"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            At <strong>DRFT Studios</strong>, we specialize in transforming groundbreaking ideas into successful, market-ready products. Whether you're a startup looking for your first breakthrough or an established business seeking innovation, our team is dedicated to guiding you through every phase of the development journey—from concept validation to launch and beyond.
          </motion.p>
          <motion.p 
            className="text-xl mb-6"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
          >
            Our approach is rooted in a deep understanding of market dynamics, user behavior, and cutting-edge technologies. By blending strategic research, user-focused design, and best-in-class development practices, we help our clients mitigate risk, innovate faster, and unlock their full potential in an ever-evolving tech landscape.
          </motion.p>
          <motion.p 
            className="text-xl mb-12"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.6 }}
          >
            At DRFT Studios, we're more than just developers—we're your strategic partner in turning vision into reality, ensuring every step is aligned with your business goals.
          </motion.p>
        </div>
        
        {/* Updated Team Members Section */}
        <motion.h2 
          className="text-4xl font-bold mb-16 text-center"
          initial={{ opacity: 0, y: -30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 1 }}
        >
          Meet Our Team
        </motion.h2>
        <div className="flex justify-center gap-12 mb-20">
          {teamMembers.map((member, index) => (
            <motion.div 
              key={index}
              className="text-center"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 1.2 + index * 0.2 }}
            >
              <div className="mb-6 relative inline-block">
                <div className="w-48 h-48 rounded-full overflow-hidden border-4 border-orange-500 shadow-lg">
                  <img 
                    src={member.image}
                    alt={member.name} 
                    className="w-full h-full object-cover"
                  />
                </div>
              </div>
              <h3 className="text-2xl font-bold mb-2">{member.name}</h3>
              <h4 className="text-yellow-400 mb-4">{member.role}</h4>
              <p className="text-gray-300">{member.bio}</p>
            </motion.div>
          ))}
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-20">
          {[
            { icon: <Lightbulb size={48} />, title: "Innovative Thinking" },
            { icon: <Code size={48} />, title: "Cutting-edge Tech" },
            { icon: <Rocket size={48} />, title: "Rapid Growth" }
          ].map((item, index) => (
            <motion.div 
              key={index}
              className="bg-gray-800 p-6 rounded-lg text-center"
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5, delay: 0.8 + index * 0.2 }}
            >
              <div className="text-yellow-400 mb-4 flex justify-center">{item.icon}</div>
              <h3 className="text-xl font-bold">{item.title}</h3>
            </motion.div>
          ))}
        </div>
        
        <div className="relative h-64 overflow-hidden mt-16">
          <svg className="absolute bottom-0 left-0 w-full" viewBox="0 0 1440 320" xmlns="http://www.w3.org/2000/svg">
            <path 
              fill="#4B5563" 
              fillOpacity="1" 
              d="M0,160L48,144C96,128,192,96,288,90.7C384,85,480,107,576,112C672,117,768,107,864,101.3C960,96,1056,96,1152,106.7C1248,117,1344,139,1392,149.3L1440,160L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
            >
              <animate 
                attributeName="d" 
                dur="10s" 
                repeatCount="indefinite"
                values="
                  M0,160L48,144C96,128,192,96,288,90.7C384,85,480,107,576,112C672,117,768,107,864,101.3C960,96,1056,96,1152,106.7C1248,117,1344,139,1392,149.3L1440,160L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z;
                  M0,192L48,181.3C96,171,192,149,288,149.3C384,149,480,171,576,165.3C672,160,768,128,864,117.3C960,107,1056,117,1152,128C1248,139,1344,149,1392,154.7L1440,160L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z;
                  M0,160L48,144C96,128,192,96,288,90.7C384,85,480,107,576,112C672,117,768,107,864,101.3C960,96,1056,96,1152,106.7C1248,117,1344,139,1392,149.3L1440,160L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
              />
            </path>
          </svg>
          
          <motion.div
            className="absolute bottom-8 left-1/2 transform -translate-x-1/2"
            animate={{ y: [0, -10, 0] }}
            transition={{ duration: 2, repeat: Infinity, ease: "easeInOut" }}
          >
            <button 
              className="w-16 h-16 bg-yellow-400 rounded-full flex items-center justify-center cursor-pointer shadow-lg hover:bg-yellow-300 transition-colors duration-300"
              onClick={scrollToTop}
              aria-label="Scroll to top"
            >
              <ChevronUp size={32} className="text-gray-900" />
            </button>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default About;