import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Layout from './components/Layout';
import DRFTStudiosHomepage from './DRFTStudiosHomepage';
import About from './About';
import GetInTouch from './GetInTouch';
import RaisingCapitalBlog from './/Blogposts/RaisingCapital'
import IdeaValidationBlog from './/Blogposts/IdeaValidationBlog';
import BrandIdentityBlogPost from './/Blogposts/BrandIdentityBlogPost';

const App = () => {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<DRFTStudiosHomepage />} />
          <Route path="/about" element={<About />} />
          <Route path="/get-in-touch" element={<GetInTouch />} />
          <Route path="/blog/raise-capital-or-not" element={<RaisingCapitalBlog />} />
          <Route path="/blog/idea-validation-for-startups" element={<IdeaValidationBlog />} />
          <Route path="/blog/importance-of-brand-identity" element={<BrandIdentityBlogPost />} />
        </Routes>
      </Layout>
    </Router>
  );
};

export default App;