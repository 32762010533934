import React, { useEffect, useState } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { Lightbulb, Code, Palette, Rocket, TrendingUp } from 'lucide-react';

const timelineSteps = [
  { title: "Idea Validation", description: "We rigorously validate your business idea through deep market research, user surveys, and interviews.", icon: <Lightbulb size={24} /> },
  { title: "MVP Development", description: "Create a refined, semi-technical MVP that's aligned with your audience and ready for growth.", icon: <Code size={24} /> },
  { title: "Design & Branding", description: "Develop a strong brand identity and intuitive design that resonates with your target market.", icon: <Palette size={24} /> },
  { title: "Full Development", description: "Transform your validated idea into a market-ready product with flawless functionality.", icon: <Rocket size={24} /> },
  { title: "Launch & Growth", description: "Execute a comprehensive go-to-market strategy and provide ongoing support for sustainable growth.", icon: <TrendingUp size={24} /> }
];

const EpicTimeline = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    threshold: 0.1,
    triggerOnce: false,
  });
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    } else {
      controls.start('hidden');
    }
  }, [controls, inView]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const windowHeight = window.innerHeight;
      const timelineElement = document.getElementById('epic-timeline');
      
      if (timelineElement) {
        const timelineTop = timelineElement.offsetTop;
        const timelineHeight = timelineElement.offsetHeight;
        const timelineBottom = timelineTop + timelineHeight;
        
        if (scrollPosition + windowHeight > timelineTop && scrollPosition < timelineBottom) {
          const progressPercentage = ((scrollPosition + windowHeight * 0.75 - timelineTop) / (timelineHeight * 0.8)) * 100;
          setProgress(Math.min(100, Math.max(0, progressPercentage)));
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: 'easeOut',
      },
    },
  };

  return (
    <section ref={ref} id="epic-timeline" className="py-20 bg-gray-900">
      <div className="container mx-auto px-4">
        <h2 className="text-5xl font-bold mb-16 text-center text-white">Our Epic Process</h2>
        <motion.div
          variants={containerVariants}
          initial="hidden"
          animate={controls}
          className="relative flex flex-col items-center"
        >
          <div className="absolute left-1/2 top-0 bottom-0 w-1 bg-yellow-400 opacity-20 transform -translate-x-1/2" />
          <motion.div 
            className="absolute left-1/2 top-0 w-1 bg-yellow-400 transform -translate-x-1/2 origin-top"
            style={{ height: `${progress}%` }}
            initial={{ scaleY: 0 }}
            animate={{ scaleY: progress / 100 }}
            transition={{ duration: 0.5 }}
          />
          {timelineSteps.map((step, index) => {
            const stepProgress = (index+1) / timelineSteps.length * 100;
            const isActive = progress >= stepProgress;
            return (
              <motion.div
                key={index}
                variants={itemVariants}
                className="mb-12 flex items-center w-full"
              >
                <div className="w-5/12 pr-4 text-left">
                  <motion.h3 
                    className="text-2xl font-bold text-yellow-400 mb-2"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: isActive ? 1 : 0 }}
                    transition={{ duration: 0.5 }}
                  >
                    {step.title}
                  </motion.h3>
                  <motion.p 
                    className="text-lg text-white"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: isActive ? 1 : 0 }}
                    transition={{ duration: 0.5, delay: 0.2 }}
                  >
                    {step.description}
                  </motion.p>
                </div>
                <div className="w-2/12 flex justify-center relative">
                  <motion.div
                    className={`w-12 h-12 rounded-full flex items-center justify-center z-10 ${
                      isActive ? 'bg-yellow-400' : 'bg-gray-700'
                    }`}
                    whileHover={{ scale: 1.1 }}
                  >
                    <div className={isActive ? 'text-gray-900' : 'text-yellow-400'}>
                      {step.icon}
                    </div>
                  </motion.div>
                </div>
                <div className="w-5/12 pl-4">
                  {/* Empty div for spacing */}
                </div>
              </motion.div>
            );
          })}
        </motion.div>
      </div>
    </section>
  );
};

export default EpicTimeline;