import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useForm } from 'react-hook-form';
import { Player } from '@lottiefiles/react-lottie-player';
import { ChevronUp, Send } from 'lucide-react';
import emailjs from 'emailjs-com';

const GetInTouch = () => {
  const { register, handleSubmit, formState: { errors }, reset } = useForm();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const onSubmit = async (data) => {
    try {
      const templateParams = {
        from_name: data.businessName,
        from_email: data.email,
        message: data.message
      };

      await emailjs.send(
        'service_6itnkkd',  // Replace with your EmailJS service ID
        'template_9vzhusf', // Replace with your EmailJS template ID
        templateParams,
        'pE-KrUU4jHguQv-Wt'      // Replace with your EmailJS user ID
      );

      setIsSubmitted(true);
      setIsError(false);
      reset();
    } catch (error) {
      console.error('Failed to send email:', error);
      setIsError(true);
    }
  };

  return (
    <div className="bg-[#082340] text-white min-h-screen">
      <div className="container mx-auto py-20 px-4">
        <motion.h1 
          className="text-5xl font-bold mb-12 text-center"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          Get in Touch
        </motion.h1>
        <div className="max-w-6xl mx-auto flex flex-col md:flex-row items-center gap-12">
          <motion.div 
            className="w-full md:w-2/5"
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            <Player
              autoplay
              loop
              src="https://assets3.lottiefiles.com/packages/lf20_jtbfg2nb.json"
              style={{ height: '400px', width: '100%' }}
            />
          </motion.div>
          <motion.div 
            className="w-full md:w-3/5"
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
          >
            <AnimatePresence>
              {!isSubmitted ? (
                <motion.form 
                  className="bg-gray-800 p-8 rounded-lg shadow-lg"
                  onSubmit={handleSubmit(onSubmit)}
                  exit={{ opacity: 0, y: 20 }}
                >
                  <div className="mb-6">
                    <label htmlFor="email" className="block text-sm text-white font-medium mb-2">Email address</label>
                    <input
                      id="email"
                      type="email"
                      className="w-full px-3 py-2 bg-white text-black rounded-md focus:outline-none focus:ring-2 focus:ring-yellow-400"
                      placeholder="you@example.com"
                      {...register("email", { required: true, pattern: /^\S+@\S+$/i })}
                    />
                    {errors.email && <span className="text-red-500 text-xs mt-1">This field is required and must be a valid email</span>}
                  </div>
                  <div className="mb-6">
                    <label htmlFor="businessName" className="block text-white text-sm font-medium mb-2">Business Name</label>
                    <input
                      id="businessName"
                      type="text"
                      className="w-full px-3 py-2 bg-white text-black rounded-md focus:outline-none focus:ring-2 focus:ring-yellow-400"
                      placeholder="Your Business Name"
                      {...register("businessName", { required: true })}
                    />
                    {errors.businessName && <span className="text-red-500 text-xs mt-1">This field is required</span>}
                  </div>
                  <div className="mb-6">
                    <label htmlFor="message" className="block text-white text-sm font-medium mb-2">Message</label>
                    <textarea
                      id="message"
                      rows="4"
                      className="w-full px-3 py-2 bg-white text-black rounded-md focus:outline-none focus:ring-2 focus:ring-yellow-400"
                      placeholder="Your message"
                      {...register("message", { required: true })}
                    ></textarea>
                    {errors.message && <span className="text-red-500 text-xs mt-1">This field is required</span>}
                  </div>
                  <motion.button
                    type="submit"
                    className="w-full bg-white text-gray-900 py-3 px-4 rounded-md font-bold hover:bg-[#F15A22] transition duration-300 flex items-center justify-center"
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                  >
                    <Send size={18} className="mr-2" />
                    Send Message
                  </motion.button>
                </motion.form>
              ) : (
                <motion.div 
                  className="bg-gray-800 p-8 rounded-lg shadow-lg text-center"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                >
                  <h2 className="text-2xl font-bold mb-4 text-white">
                    {isError ? "Oops! Something went wrong." : "Thank you for your message!"}
                  </h2>
                  <p className="mb-4 text-white">
                    {isError ? "Please try again later or contact us directly." : "We'll get back to you soon."}
                  </p>
                  <Player
                    autoplay
                    loop
                    src={isError ? "https://assets9.lottiefiles.com/packages/lf20_error.json" : "https://assets3.lottiefiles.com/packages/lf20_jtbfg2nb.json"}
                    style={{ height: '200px', width: '200px', margin: '0 auto' }}
                  />
                  {isError && (
                    <motion.button
                      className="mt-4 bg-yellow-400 text-gray-900 py-2 px-4 rounded-md font-bold hover:bg-blue-700 transition duration-300"
                      onClick={() => {setIsSubmitted(false); setIsError(false);}}
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                    >
                      Try Again
                    </motion.button>
                  )}
                </motion.div>
              )}
            </AnimatePresence>
          </motion.div>
        </div>
      </div>

      {/* Floating scroll-to-top button */}
      <motion.div
        className="fixed bottom-8 right-8 z-50"
        animate={{ y: [0, -10, 0] }}
        transition={{ duration: 2, repeat: Infinity, ease: "easeInOut" }}
      >
        <button 
          className="w-12 h-12 bg-yellow-400 rounded-full flex items-center justify-center cursor-pointer shadow-lg hover:bg-yellow-300 transition-colors duration-300" 
          onClick={scrollToTop}
          aria-label="Scroll to top"
        >
          <ChevronUp size={24} className="text-gray-900" />
        </button>
      </motion.div>
    </div>
  );
};

export default GetInTouch;